import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import styles from './ReportEdition.module.scss';
import ReportAppBar from 'components/ReportAppBar/ReportAppBar';
import { getAthleteEvaluation } from 'store/ducks/evaluations';
import {
  Card,
  Table,
  Button,
  TextArea,
  Avatar,
  Input,
  Menu,
  Checkbox,
  RadarChart,
  StarRating,
} from 'components/template';
import FieldList from 'pages/Athlete/Evaluation/FieldList/FieldList';
import CenteredLoader from 'components/template/CenteredLoader';
import ImageList from 'components/ImageList/ImageList';
import { FaLongArrowAltRight, FaThumbsDown, FaThumbsUp } from 'react-icons/fa';
import TeamRow from 'components/TeamRow/TeamRow';
import IcoTempo from 'assets/ico/ico-tempo.png';
import IcoJogos from 'assets/ico/ico-jogos.png';
import IcoTitular from 'assets/ico/ico-titular.png';
import IcoEntrou from 'assets/ico/ico-entrou.png';
import IcoSaiu from 'assets/ico/ico-saiu.png';
import IcoGols from 'assets/ico/ico-gols.png';
import IcoAssistencias from 'assets/ico/ico-assistencias.png';
import IcoAmarelos from 'assets/ico/ico-amarelos.png';
import IcoAmareloVermelho from 'assets/ico/ico-amarelo-vermelho.png';
import IcoVermelhos from 'assets/ico/ico-vermelhos.png';
import { getBase64 } from 'shared/utility';
import { useTranslation } from 'react-i18next';
import { FaEye, FaRegCalendarAlt, FaFileAlt } from 'react-icons/fa';
import { IoIosFootball } from 'react-icons/io';
import CustomFields from 'pages/Athlete/Evaluation/CustomFields/CustomFields';
import { Creators as clubActions } from 'store/ducks/club';
import { useMount } from 'shared/hooks';
import Variables from 'variables';

const cargosSelect = [
  { label: 'Observador Técnico', value: 'observador_tecnico' },
  { label: 'Coordenador Iniciação', value: 'coordenador_iniciacao' },
  { label: 'Diretor Executivo', value: 'diretor_executivo' },
  { label: 'Diretor Estatutário', value: 'diretor_estatutario' },
  { label: 'Técnico Sub 20', value: 'tecnico_sub_20' },
  { label: 'Técnico Sub 18', value: 'tecnico_sub_18' },
  { label: 'Técnico Sub 17', value: 'tecnico_sub_17' },
  { label: 'Técnico Sub 16', value: 'tecnico_sub_16' },
  { label: 'Técnico Sub 15', value: 'tecnico_sub_15' },
  { label: 'Técnico Sub 14', value: 'tecnico_sub_14' },
  { label: 'Técnico Sub 13', value: 'tecnico_sub_13' },
  { label: 'Técnico Sub 12', value: 'tecnico_sub_12' },
  { label: 'Técnico Sub 11', value: 'tecnico_sub_11' },
  { label: 'Técnico Sub 10', value: 'tecnico_sub_10' },
  { label: 'Treinador de Goleiros Sub 20', value: 'treinador_goleiros_sub_20' },
  { label: 'Treinador de Goleiros Sub 17', value: 'treinador_goleiros_sub_17' },
  { label: 'Treinador de Goleiros Sub 15', value: 'treinador_goleiros_sub_15' },
  { label: 'Treinador de Goleiros Iniciação', value: 'treinador_goleiros_iniciacao' },
];

let fileIndex = 0;
export default function ReportEdition({
  data,
  configuration,
  setConfiguration,
  setAthletesInfoData,
  athletesInfoData,
  athleteInfoData,
}) {
  const user = useSelector(state => state.user);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [modifiedAthlete, setModifiedAthlete] = useState(athleteInfoData);
  const [imagesFileInputValue, setImagesFileInputValue] = useState([]);
  const evaluationFields = useSelector(state => state.club.evaluationFields);

  const [editingField, setEditingField] = useState({
    generalFeatures: false,
    opinion: false,
    background: false,
  });

  useMount(() => {
    dispatch(clubActions.getEvaluationFields({ is_new_evaluation: true }));
    return () => dispatch(clubActions.resetEvaluationFields());
  });

  useEffect(() => {
    let athletesInfoDataCopy = [...athletesInfoData];

    athletesInfoDataCopy = athletesInfoDataCopy.map(athlete => {
      if (athlete.id === modifiedAthlete.id) {
        return modifiedAthlete;
      } else {
        return athlete;
      }
    });
    setAthletesInfoData(athletesInfoDataCopy);
  }, [modifiedAthlete, imagesFileInputValue]); // eslint-disable-line react-hooks/exhaustive-deps

  const rawData = data.athlete;
  const evaluationsData = data.evaluations;
  const historicalData = data.historical;
  const transfersData = data.transfers;
  const contractsData = data.contracts;

  const inputFileRef = useRef(null);

  const addFile = (arr, file) => {
    arr.push({
      id: fileIndex,
      title: '',
      file: file,
    });
    fileIndex++;

    setImagesFileInputValue(arr);
    setModifiedAthlete(f => ({ ...f, files: arr }));
  };

  const handleFileUpload = async e => {
    let copy = [...imagesFileInputValue];
    for (let val of e.target.files) {
      getBase64(val, result => addFile(copy, result));
    }
  };

  const triggerInputFile = () => inputFileRef.current.click();

  const handleInputChange = e => {
    e.persist();
    setModifiedAthlete({ ...modifiedAthlete, [e.target.name]: e.target.value });
  };

  if (rawData.agency && Object.keys(modifiedAthlete.negotiation).length === 0) {
    const newNegotiation = { agency: rawData.agency.name };
    if (rawData.agency.phone1?.number) {
      newNegotiation.agency_phone = `${rawData.agency.phone1.ddd ?? ''} ${rawData.agency.phone1.number}`;
    }
    if (rawData.agency.whatsapp?.number) {
      newNegotiation.agency_whatsapp = `${rawData.agency.whatsapp.ddd ?? ''} ${rawData.agency.whatsapp.number}`;
    }
    setModifiedAthlete({ ...modifiedAthlete, negotiation: newNegotiation });
  }

  const nationalities = rawData.nationalities?.length
    ? rawData.nationalities.map(item => (
        <>
          <p>{t('athlete_card.nation')}:</p>
          <div>
            <span style={{ fontWeight: '600' }}>{item.name}</span>
            <img src={item.flag} alt={`Bandeira do ${item.name}`} key={item.name} />
          </div>
        </>
      ))
    : '';

  const athleteData = (
    <Card softShadow className={styles.mainInfo}>
      <div className={styles.athletePhotoDiv}>
        <div className={styles.athletePhoto} style={{ backgroundImage: `url(${modifiedAthlete.photo})` }} />
      </div>
      <div className={styles.athleteInfo}>
        <div className={styles.generalData}>
          <div className={styles.leftContent}>
            <div className={styles.athleteName}>
              <a
                href={`/atletas/${modifiedAthlete.id}`}
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: 'none', color: '#000' }}
              >
                <p>{modifiedAthlete.nickname}</p>
              </a>
              <span>{modifiedAthlete.fullname}</span>
            </div>
            <div className={styles.athleteAge}>
              <div className={styles.formGroup}>
                <div>
                  <p>{t('athlete_list.box4')}:</p>
                  <span>{t(`positionOptionsRaw.${modifiedAthlete.position}`)}</span>
                </div>
                <div style={{ borderLeft: '1px solid black', height: '12px' }} />
                <div>
                  <p>{t('athlete_list.box3')}:</p>
                  <span>
                    '{rawData.birthyear % 100} ({rawData.age} {t('athlete_card.idade')})
                  </span>
                </div>
                <div style={{ borderLeft: '1px solid black', height: '12px' }} />
                <div>{nationalities}</div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.aditionalData}>
          <div>
            <div className={styles.formGroup}>
              <p>{t('athlete_card.birth')}</p>
              <span>{modifiedAthlete.birthdate && moment(modifiedAthlete.birthdate).format('DD/MM/YYYY')}</span>
            </div>
            <div className={styles.formGroup}>
              <p>{t('athlete_list.box3')}:</p>
              <span>{modifiedAthlete.age} anos</span>
            </div>
            <div className={styles.formGroup}>
              <p>{t('club.text13')}:</p>
              <span>{modifiedAthlete.height ? modifiedAthlete.height + 'cm' : '-'}</span>
            </div>
          </div>
          <div>
            <div className={styles.formGroup}>
              <p>{t('athlete_list.box4')}:</p>
              <span>{t(`positionOptionsRaw.${modifiedAthlete.position}`)}</span>
            </div>
            <div className={styles.formGroup}>
              <p>{t('athlete_card.foot')}</p>
              <span>{modifiedAthlete.foot}</span>
            </div>
            <div className={styles.formGroup}>
              <p>{t('club.text14')}:</p>
              <span>{modifiedAthlete.weight ? modifiedAthlete.weight + 'kg' : '-'}</span>
            </div>
          </div>
          <div className={styles.clubInfo}>
            {rawData.current_team && (
              <div className={styles.formGroup}>
                <p>{t('agencies.text2')}:</p>
                <span className={styles.flags}>
                  <img
                    src={rawData.current_team?.country?.flag}
                    alt={`Bandeira do ${rawData.current_team?.country?.name}`}
                  />
                  {rawData.current_team?.emblem ? (
                    <img src={rawData.current_team?.emblem} alt={`Bandeira do ${rawData.current_team?.nickname}`} />
                  ) : (
                    <Avatar
                      img={rawData.current_team?.emblem}
                      name={rawData.current_team?.nickname}
                      className={styles.avatar}
                    />
                  )}
                  <span>{rawData.current_team?.nickname}</span>
                </span>
              </div>
            )}
            {rawData.contract_info?.current_contract_end && (
              <div className={styles.formGroup}>
                <p>{t('athlete_list.box6')}:</p>
                <span>{moment(rawData.contract_info?.current_contract_end).format('DD/MM/YYYY')}</span>
              </div>
            )}
          </div>
          <div className={styles.clubInfo}>
            {rawData.src_team && (
              <div className={styles.formGroup}>
                <p>{t('agencies.text4')}:</p>
                <span className={styles.flags}>
                  <img src={rawData.src_team?.country?.flag} alt={`Bandeira do ${rawData.src_team?.country?.name}`} />
                  {rawData.src_team?.emblem ? (
                    <img src={rawData.src_team?.emblem} alt={`Bandeira do ${rawData.src_team?.country?.nickname}`} />
                  ) : (
                    <Avatar
                      img={rawData.src_team?.emblem}
                      name={rawData.src_team?.nickname}
                      className={styles.avatar}
                    />
                  )}
                  <span>{rawData.src_team?.nickname}</span>
                </span>
              </div>
            )}
            {rawData.contract_info?.src_contract_end && (
              <div className={styles.formGroup}>
                <p>{t('athlete_list.box6')}:</p>
                <span>{moment(rawData.contract_info?.src_contract_end).format('DD/MM/YYYY')}</span>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* <div className={styles.athleteInfo}>
                <div className={styles.generalData}>
                    <div className={styles.leftContent}>
                        <div className={styles.athleteName}>
                            <span><Input rounded name="nickname" value={modifiedAthlete.nickname} onChange={(e) => handleInputChange(e)} /></span>
                            <span><Input rounded name="fullname" value={modifiedAthlete.fullname} onChange={(e) => handleInputChange(e)} /></span>
                        </div>
                        <div className={styles.athleteAge}>
                            <div className={styles.formGroup}>
                                <label>Posição:</label>
                                <span><Input rounded name="position" value={modifiedAthlete.position} onChange={(e) => handleInputChange(e)} /></span>
                                <label>Idade:</label>
                                <span>
                                    '{rawData.birthyear % 100} ({rawData.age} anos)
                                </span>
                                {nationalities}
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.aditionalData}>
                    <div className={styles.birthDiv}>
                        <div className={styles.formGroup}>
                            <label>Nascimento:</label>
                            <Calendar name="birthdate" value={new Date(modifiedAthlete.birthdate)} setValue={(e) => handleCalendarChange(e)} />
                        </div>
                        <div className={styles.formGroup}>
                            <label>Idade:</label>
                            <span>{rawData.age} anos</span>
                        </div>
                        <div className={styles.formGroup}>
                            <label>Altura:</label>
                            <Input rounded name="height" value={(modifiedAthlete.height && modifiedAthlete.height + ' cm') || '-'} onChange={(e) => handleInputChange(e)} />
                        </div>
                        <div className={styles.formGroup}>
                            <label>Peso:</label>
                            <Input rounded name="weight" value={(modifiedAthlete.weight && modifiedAthlete.weight + ' kg') || '-'} onChange={(e) => handleInputChange(e)} />
                        </div>
                    </div>
                    <div className={styles.positionDiv}>
                        <div className={styles.formGroup}>
                            <label>Posição:</label>
                            <Input rounded name="position" value={modifiedAthlete.position} onChange={(e) => handleInputChange(e)} />
                        </div>
                        <div className={styles.formGroup}>
                            <label>Pé preferencial:</label>
                            <Input rounded name="foot" value={modifiedAthlete.foot} onChange={(e) => handleInputChange(e)} />
                        </div>
                        <div className={styles.formGroup}>
                            <label>Gols registrados:</label>
                            <Input rounded name="totalGoals" value={modifiedAthlete.totalGoals} onChange={(e) => handleInputChange(e)} />
                        </div>
                        <div className={styles.formGroup}>
                            <label>Jogos registrados:</label>
                            <Input rounded name="totalMatches" value={modifiedAthlete.totalMatches} onChange={(e) => handleInputChange(e)} />
                        </div>
                    </div>
                    <div className={styles.clubInfo}>
                        {rawData.contract_info?.current_team && (
                            <div className={styles.formGroup}>
                                <label>Clube atual:</label>
                                <span className={styles.flags}>
                                    {rawData.contract_info?.current_team && (
                                        <img
                                            src={rawData.contract_info?.current_team?.country?.flag}
                                            alt={`Bandeira do ${rawData.contract_info?.current_team?.country?.name}`}
                                        />
                                    )}
                                    {rawData.contract_info?.current_team && (
                                        <img
                                            src={rawData.contract_info?.current_team?.emblem}
                                            alt={`Bandeira do ${rawData.contract_info?.current_team?.nickname}`}
                                        />
                                    )}
                                    <span>{rawData.contract_info?.current_team?.nickname}</span>
                                </span>
                            </div>
                        )}
                        {rawData.contract_info?.current_contract_end && (
                            <div className={styles.formGroup}>
                                <label>Fim do contrato:</label>
                                <span>
                                    {rawData.contract_info?.current_contract_end &&
                                        moment(rawData.contract_info?.current_contract_end).format(
                                            'DD/MM/YYYY'
                                        )}
                                </span>
                            </div>
                        )}
                    </div>
                    <div className={styles.clubInfo}>
                        {rawData.contract_info?.src_team && (
                            <div className={styles.formGroup}>
                                <label>Clube cedente:</label>
                                <span className={styles.flags}>
                                    <img
                                        src={rawData.contract_info?.src_team?.country?.flag}
                                        alt={`Bandeira do ${rawData.contract_info?.src_team?.country?.name}`}
                                    />
                                    <img
                                        src={rawData.contract_info?.src_team?.emblem}
                                        alt={`Bandeira do ${rawData.contract_info?.src_team?.country?.nickname}`}
                                    />
                                    <span>{rawData.contract_info?.src_team?.nickname}</span>
                                </span>
                            </div>
                        )}
                        {rawData.contract_info?.src_contract_end && (
                            <div className={styles.formGroup}>
                                <label>Fim do contrato:</label>
                                <span>
                                    {moment(rawData.contract_info?.src_contract_end).format('DD/MM/YYYY')}
                                </span>
                            </div>
                        )}
                    </div>
                </div>
            </div> */}
    </Card>
  );

  const [fieldsEvaluation, setFieldsEvaluation] = useState([]);
  const evaluationsOptions = evaluationsData.map(e => {
    const generalEvaluation = parseFloat((e.general_evaluation ?? 0).toString());
    return {
      label: `${moment(e.evaluation_date).format('DD/MM/YYYY')} - (${t('rest.box5')} ${generalEvaluation.toFixed(
        1
      )}) ${e.author?.name ?? ''}`,
      value: `${e.id}`,
    };
  });

  const athleteEvaluations = modifiedAthlete.evaluationId
    ? evaluationsData.find(e => e.id === modifiedAthlete.evaluationId)
    : undefined;

  const [generalRate, setGeneralRate] = useState(0);

  useEffect(() => {
    if (fieldsEvaluation?.length) {
      const sumRates = fieldsEvaluation.map(f => f?.rate ?? 0).reduce((acc, rate) => acc + rate);
      setGeneralRate(sumRates / fieldsEvaluation?.length);
    }
  }, [fieldsEvaluation]);

  useEffect(() => {
    if (configuration.evaluations && athleteEvaluations && fieldsEvaluation.length === 0) {
      getAthleteEvaluation(modifiedAthlete.id, athleteEvaluations.id).then(data => {
        if (data && data.error === undefined && data.data) {
          const evaFields = evaluationFields.data.find(s => s.mode === modifiedAthlete.mode);
          let thisModifiedAthlete = modifiedAthlete;
          if (modifiedAthlete.opinion.length > 0 && modifiedAthlete.background.length > 0) {
            thisModifiedAthlete.opinion = modifiedAthlete.opinion;
            thisModifiedAthlete.background = modifiedAthlete.background;
          } else {
            if (data.data.values.context_fields && data.data.values.opinion_fields) {
              thisModifiedAthlete.opinion = data.data.values.opinion_fields ?? [];
              thisModifiedAthlete.background = data.data.values.context_fields ?? [];
            } else {
              if ('feedback' in data.data) {
                thisModifiedAthlete.opinion =
                  evaFields?.data?.opinion_fields.map(field => {
                    if (field.name === 'parecer') {
                      return { ...field, value: data?.data?.feedback ?? '' };
                    } else if (field.name === 'context_parecer') {
                      return { ...field, value: data?.data?.teamContext ?? '' };
                    } else {
                      return field;
                    }
                  }) ?? [];
              }
              if ('teamContext' in data.data) {
                thisModifiedAthlete.background =
                  evaFields?.data?.context_fields.map(field => {
                    if (field.name === 'context_parecer') {
                      return { ...field, value: data?.data?.teamContext ?? '' };
                    } else {
                      return field;
                    }
                  }) ?? [];
              }
            }
          }

          if (modifiedAthlete.microaspects.length) {
            if (thisModifiedAthlete.microaspects.length === 0 && data.data.values?.fields) {
              thisModifiedAthlete.microaspects = data.data.values.fields.map(fe => fe.feedback);
            }
          }

          if (modifiedAthlete.assigns) {
            thisModifiedAthlete.assigns = modifiedAthlete.assigns;
          }

          setModifiedAthlete({
            ...thisModifiedAthlete,
            author: data.author,
            method: data.method,
            mode: data.mode,
            created_at: data.created_at,
          });
          const dataFields = data?.data?.values?.fields ?? [];
          dataFields.forEach((df, index) => {
            if (index < thisModifiedAthlete.microaspects.length) {
              df.feedback = thisModifiedAthlete.microaspects[index];
            }
          });
          setFieldsEvaluation(dataFields);
        }
      });
    }
  }, [configuration, modifiedAthlete]); // eslint-disable-line react-hooks/exhaustive-deps

  const updateParecerFields = useCallback(newFields => {
    setModifiedAthlete(prev => ({ ...prev, opinion: newFields }));
  }, []);

  const updateContextoFields = useCallback(newFields => {
    setModifiedAthlete(prev => ({ ...prev, background: newFields }));
  }, []);

  const athleteHistorical = historicalData.map(row => {
    const season = row.season;
    const team = (
      <TeamRow name={row.team?.nickname} id={row.team?.id} img={row.team?.emblem} className={styles.teamRow} />
    );
    const competitionName = row.competition_name;
    const minutesPlayed = row.minutes_played;
    const appearances = row.appearances;
    const lineups = row.lineups;
    const subsIn = row.subs_in;
    const subsOut = row.subs_out;
    const goals = row.goals;
    const assists = row.assists;
    const yellowCards = row.yellow_cards;
    const secondYellow = row.second_yellow_cards;
    const redCards = row.red_cards;

    return [
      season,
      team,
      competitionName,
      minutesPlayed,
      appearances,
      lineups,
      subsIn,
      subsOut,
      goals,
      assists,
      yellowCards,
      secondYellow,
      redCards,
    ];
  });

  function athleteHistoricalReducer() {
    if (athleteHistorical.length > 0) {
      let result = athleteHistorical;
      let prevSeason = athleteHistorical[0][0];
      let currentSeason = '';
      let arr = [];

      let minutesPlayed = athleteHistorical[0][3];
      let appearances = athleteHistorical[0][4];
      let lineups = athleteHistorical[0][5];
      let subsIn = athleteHistorical[0][6];
      let subsOut = athleteHistorical[0][7];
      let goals = athleteHistorical[0][8];
      let assists = athleteHistorical[0][9];
      let yellowCards = athleteHistorical[0][10];
      let secondYellow = athleteHistorical[0][11];
      let redCards = athleteHistorical[0][12];

      for (let i = 1; i < athleteHistorical.length; i++) {
        currentSeason = athleteHistorical[i][0];

        if (prevSeason === currentSeason) {
          minutesPlayed += athleteHistorical[i][3];
          appearances += athleteHistorical[i][4];
          lineups += athleteHistorical[i][5];
          subsIn += athleteHistorical[i][6];
          subsOut += athleteHistorical[i][7];
          goals += athleteHistorical[i][8];
          assists += athleteHistorical[i][9];
          yellowCards += athleteHistorical[i][10];
          secondYellow += athleteHistorical[i][11];
          redCards += athleteHistorical[i][12];
        } else {
          arr = [
            '',
            '',
            t('club.box9') + prevSeason,
            minutesPlayed,
            appearances,
            lineups,
            subsIn,
            subsOut,
            goals,
            assists,
            yellowCards,
            secondYellow,
            redCards,
          ];
          result.splice(i, 0, arr);

          prevSeason = currentSeason;
          minutesPlayed = 0;
          appearances = 0;
          lineups = 0;
          subsIn = 0;
          subsOut = 0;
          goals = 0;
          assists = 0;
          yellowCards = 0;
          secondYellow = 0;
          redCards = 0;
        }
      }

      let last = result.length - 1;

      minutesPlayed = athleteHistorical[last][3];
      appearances = athleteHistorical[last][4];
      lineups = athleteHistorical[last][5];
      subsIn = athleteHistorical[last][6];
      subsOut = athleteHistorical[last][7];
      goals = athleteHistorical[last][8];
      assists = athleteHistorical[last][9];
      yellowCards = athleteHistorical[last][10];
      secondYellow = athleteHistorical[last][11];
      redCards = athleteHistorical[last][12];

      arr = [
        '',
        '',
        t('club.box9') + currentSeason,
        minutesPlayed,
        appearances,
        lineups,
        subsIn,
        subsOut,
        goals,
        assists,
        yellowCards,
        secondYellow,
        redCards,
      ];
      result.push(arr);

      return result;
    } else return [];
  }
  const athleteHistoricalReduce = athleteHistoricalReducer();

  let athleteTransfersTotal = 0;
  const athleteTransfers = transfersData.map(row => {
    const season = row.season;
    const date = row.transfer_date && moment(row.transfer_date).format('DD/MM/YYYY');
    const srcTeam = row.src_team && (
      <div className={styles.flagsDiv}>
        {row.src_team?.country?.flag && (
          <img
            className={styles.countryFlag}
            src={row.src_team?.country?.flag}
            alt={row.src_team?.country?.name ?? ''}
          />
        )}
        <TeamRow name={row.src_team?.nickname} id={row.src_team?.id} img={row.src_team?.emblem} />
      </div>
    );
    const dstTeam = row.dst_team && (
      <div className={styles.flagsDiv}>
        <FaLongArrowAltRight />
        {row.dst_team?.country?.flag && (
          <img
            className={styles.countryFlag}
            src={row.dst_team?.country?.flag}
            alt={row.dst_team?.country?.name ?? ''}
          />
        )}
        <TeamRow name={row.dst_team?.nickname} id={row.dst_team?.id} img={row.dst_team?.emblem} />
      </div>
    );
    const age = `${row.age_at_date.years} ${t('athlete_card.idade')}}, ${row.age_at_date.months} ${t(
      'athlete_card.meses'
    )}} ${row.age_at_date.days} ${t('athlete_card.days')}}`;
    const value = row.transfer_value;

    if (row.monetary_value !== null) athleteTransfersTotal = athleteTransfersTotal + row.monetary_value;

    return [season, date, srcTeam, dstTeam, age, value];
  });

  const athleteContracts =
    contractsData &&
    contractsData.map(row => {
      const bidDate = row.contract_info?.bid_date && moment(row.contract_info?.bid_date).format('DD/MM/YYYY');
      const team = <TeamRow name={row.team?.nickname} id={row.team?.id} img={row.team?.emblem} />;
      const state = row.contract_info?.state;
      const type = row.contract_info?.type;

      const contractActive = moment(row.contract_info.end, 'YYYY-MM-DD')._d >= moment();
      const contract = row.contract_info ? (
        <span className={styles.contractStatus}>
          {contractActive ? (
            <div className={`${styles.dotIcon} ${styles.purpleDot}`}></div>
          ) : (
            <div className={styles.dotIcon}></div>
          )}
          {row.contract_info.contract}
        </span>
      ) : (
        ''
      );
      const start = row.contract_info?.start && (
        <span className={contractActive ? styles.contractActive : styles.contractInactive}>
          {moment(row.contract_info?.start).format('DD/MM/YYYY')}
        </span>
      );
      const end = row.contract_info?.end && (
        <span className={contractActive ? styles.contractActive : styles.contractInactive}>
          {moment(row.contract_info?.end).format('DD/MM/YYYY')}
        </span>
      );

      return [bidDate, team, state, type, contract, start, end];
    });

  const radarChartLabels = fieldsEvaluation ? fieldsEvaluation.map(item => String(item.title)) : [];
  const radarChartSeries = fieldsEvaluation ? fieldsEvaluation.map(item => item.rate || 0) : [];

  const plotOptions = {
    chart: {
      background: 'white',
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      radar: {
        size: 120,
        polygons: {
          strokeColors: '#ccc',
          connectorColors: '#ccc',
          fill: {
            colors: ['#fff', '#e0e0e0'],
          },
        },
      },
    },
    xaxis: {
      labels: {
        style: { colors: Array(fieldsEvaluation.length).fill('#000'), fontSize: '12px' },
      },
    },
  };

  const radarChartData = {
    labels: radarChartLabels,
    series: [
      {
        data: radarChartSeries,
      },
    ],
  };

  return (
    <>
      <ReportAppBar />
      <div className={styles.allInfo}>
        {athleteData}
        {(configuration.generalFeatures || configuration.negotiation || configuration.images) && (
          <Card softShadow className={styles.athletePerformance}>
            {configuration.generalFeatures && (
              <div className={styles.generalData}>
                <label className={styles.labelTitle}>{t('club.box10')}</label>
                <div className={styles.textWrapper}>
                  {editingField.generalFeatures ? (
                    <TextArea
                      onBlur={() => setEditingField({ ...editingField, generalFeatures: false })}
                      fluid
                      name="generalFeatures"
                      onChange={e => handleInputChange(e)}
                      value={modifiedAthlete.generalFeatures}
                    />
                  ) : (
                    <div>
                      <span>{modifiedAthlete.generalFeatures}</span>
                      <Button
                        noShadow
                        color="link"
                        onClick={() => {
                          setEditingField({ ...editingField, generalFeatures: true });
                        }}
                      >
                        {t('field.edit')}
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            )}
            {configuration.negotiation && (
              <div className={styles.generalData} style={{ display: 'block' }}>
                <label className={styles.labelTitle}>Negociação</label>
                <div className={styles.negotiation}>
                  <div className={styles.info}>
                    <span>{t('club.box12')}:</span>
                    <span>
                      <Input
                        rounded
                        name="agency"
                        value={modifiedAthlete.negotiation?.agency ?? ''}
                        onChange={e => {
                          e.persist();
                          setModifiedAthlete({
                            ...modifiedAthlete,
                            negotiation: { ...modifiedAthlete.negotiation, agency: e.target.value },
                          });
                        }}
                      />
                    </span>
                    <span style={{ marginTop: '10px' }}>{t('contact_card.phone')}:</span>
                    <span>
                      <Input
                        rounded
                        name="agency_phone"
                        value={modifiedAthlete.negotiation?.agency_phone ?? ''}
                        onChange={e => {
                          e.persist();
                          setModifiedAthlete({
                            ...modifiedAthlete,
                            negotiation: { ...modifiedAthlete.negotiation, agency_phone: e.target.value },
                          });
                        }}
                      />
                    </span>
                    <span style={{ marginTop: '10px' }}>Whatsapp:</span>
                    <span>
                      <Input
                        rounded
                        name="agency_whatsapp"
                        value={modifiedAthlete.negotiation?.agency_whatsapp ?? ''}
                        onChange={e => {
                          e.persist();
                          setModifiedAthlete({
                            ...modifiedAthlete,
                            negotiation: { ...modifiedAthlete.negotiation, agency_whatsapp: e.target.value },
                          });
                        }}
                      />
                    </span>
                  </div>
                  <div className={styles.inputs}>
                    <span>{t('club.box14')}:</span>
                    <span>
                      <Input
                        rounded
                        name="investiment"
                        value={modifiedAthlete.negotiation?.investiment ?? ''}
                        onChange={e => {
                          e.persist();
                          setModifiedAthlete({
                            ...modifiedAthlete,
                            negotiation: { ...modifiedAthlete.negotiation, investiment: e.target.value },
                          });
                        }}
                      />
                    </span>
                    <span style={{ marginTop: '10px' }}>{t('club.box13')}:</span>
                    <span>
                      <Input
                        rounded
                        name="revenue"
                        value={modifiedAthlete.negotiation?.revenue ?? ''}
                        onChange={e => {
                          e.persist();
                          setModifiedAthlete({
                            ...modifiedAthlete,
                            negotiation: { ...modifiedAthlete.negotiation, revenue: e.target.value },
                          });
                        }}
                      />
                    </span>
                  </div>
                </div>
              </div>
            )}
            {configuration.images && (
              <div className={`${styles.generalData} ${styles.imagesContainer}`}>
                <div>
                  <label className={styles.labelTitle}>{t('rest.box6')}</label>
                  {modifiedAthlete.files.length < 6 && (
                    <>
                      <input
                        type="file"
                        hidden
                        multiple
                        ref={inputFileRef}
                        onChange={handleFileUpload}
                        accept="image/webp,image/png,image/jpeg"
                      />
                      <Button
                        className={styles.buttonAddFile}
                        rounded
                        smaller
                        noShadow
                        color="white"
                        onClick={triggerInputFile}
                      >
                        {t('rest.box7')}
                      </Button>
                    </>
                  )}
                  {modifiedAthlete.files.length > 0 && (
                    <ImageList
                      images={modifiedAthlete.files}
                      setImagesFileInputValue={setImagesFileInputValue}
                      setModifiedAthlete={setModifiedAthlete}
                    />
                  )}
                </div>
              </div>
            )}
          </Card>
        )}
        {configuration.evaluations &&
          (evaluationsData.length > 0 ? (
            <div className={styles.evaluation}>
              <label className={styles.labelTitle}>{t('club.box15')}</label>
              <div className={styles.evaluationtop}>
                <Menu
                  title={t('club.box15')}
                  largeMenu
                  rounded
                  value={modifiedAthlete.evaluationId.toString()}
                  setValue={id => {
                    modifiedAthlete.assigns = undefined;
                    setFieldsEvaluation([]);
                    setModifiedAthlete({ ...modifiedAthlete, evaluationId: parseInt(id), opinion: '', background: '' });
                  }}
                  options={evaluationsOptions}
                  placeholder={t('club.box15')}
                  className={styles.menuevaluation}
                />
                <Checkbox
                  checked={configuration.opinion}
                  onChange={() => {
                    setConfiguration({ ...configuration, opinion: !configuration.opinion });
                  }}
                  className={styles.checkboxevaluation}
                  label={t('rest.box8')}
                />
                <Checkbox
                  checked={configuration.background}
                  onChange={() => {
                    setConfiguration({ ...configuration, background: !configuration.background });
                  }}
                  className={styles.checkboxevaluation}
                  label={t('rest.box9')}
                />
                <Checkbox
                  checked={configuration.aspects}
                  onChange={() => {
                    setConfiguration({ ...configuration, aspects: !configuration.aspects });
                  }}
                  className={styles.checkboxevaluation}
                  label={t('rest.box10')}
                />
                <Checkbox
                  checked={configuration.microaspects}
                  onChange={() => {
                    setConfiguration({ ...configuration, microaspects: !configuration.microaspects });
                  }}
                  className={styles.checkboxevaluation}
                  label={t('rest.box11')}
                />
              </div>
              {(modifiedAthlete.evaluationId ?? 0) === 0 ? (
                <h5>{t('rest.box12')}</h5>
              ) : fieldsEvaluation.length === 0 ? (
                <CenteredLoader />
              ) : (
                <>
                  <div className={styles.evaluationHeaderInfo}>
                    <p>{t('evaluationData')}</p>
                    <div>
                      <FaEye />
                      <span>{modifiedAthlete?.author?.name ?? modifiedAthlete?.author?.email}</span>
                    </div>
                    <div>
                      <FaRegCalendarAlt />
                      <span>{new Date(modifiedAthlete.created_at).toLocaleDateString('pt-BR') || ' '}</span>
                    </div>
                    {modifiedAthlete.mode && (
                      <div>
                        <FaFileAlt /> <span>{t(`avaliationModel.${modifiedAthlete.mode}`)}</span>
                      </div>
                    )}
                  </div>
                  <div className={styles.evaluationHeaderSubInfo}>
                    <div>
                      <p>{t('table.general')}:</p>
                      <StarRating rate={athleteEvaluations.generalEvaluation} size={20} />(
                      {athleteEvaluations?.generalEvaluation ?? '0.00'})
                    </div>
                    <div>
                      {t('config.dire')}:<p>{t('report.recommendation.' + athleteEvaluations?.recommendation)}</p>
                      {athleteEvaluations?.recommendation === 'APROVAR' && (
                        <FaThumbsUp color={Variables.lightPurple1} />
                      )}
                      {athleteEvaluations?.priority === 'REPROVAR' && <FaThumbsDown color={Variables.lightPurple1} />}
                    </div>
                    <div>
                      {t('config.pri')}:
                      <div className={styles.priority}>
                        {athleteEvaluations?.priority === 'ALTA' && <div className={styles.highPriority} />}
                        {athleteEvaluations?.priority === 'MEDIA' && <div className={styles.mediumPriority} />}
                        {athleteEvaluations?.priority === 'BAIXA' && <div className={styles.lowPriority} />}
                        <p>{t('config.' + athleteEvaluations?.priority)}</p>
                      </div>
                    </div>
                  </div>
                  {configuration.opinion && (
                    <div className={styles.generalDataEvaluation}>
                      <label className={styles.labelTitle}>{t('field.pare')}</label>
                      <div className={styles.textWrapper}>
                        <CustomFields
                          fields={modifiedAthlete.opinion}
                          setFormFields={() => {}}
                          className={styles.customFields}
                          disableAll={true}
                        />
                      </div>
                    </div>
                  )}
                  {configuration.opinion && <div style={{ borderBottom: '1px solid #bdbdbd' }} />}
                  <div className={styles.generalDataEvaluation}>
                    <label className={styles.labelTitle}>{t('club.box15')}</label>
                    <div className={styles.generalEvaluationInside}>
                      <div>
                        <RadarChart height={300} width={700} data={radarChartData} options={plotOptions} />
                      </div>
                      <div className={styles.evaluationResume}>
                        <h1>{t('table.general')}</h1>
                        <div className={styles.generalEvaluationResume}>
                          <StarRating rate={generalRate || 0} size={20} />
                          <span>({generalRate ? generalRate.toFixed(2) : '0.00'})</span>
                        </div>
                        <div style={{ marginTop: '10px', gap: '10px', display: 'flex', flexDirection: 'column' }}>
                          {fieldsEvaluation.map(item => (
                            <div style={{ display: 'grid', gridAutoFlow: 'column', gridTemplateColumns: '50% 50%' }}>
                              <div>
                                <p>{item.title}:</p>
                              </div>
                              <div className={styles.generalEvaluationResume}>
                                <StarRating rate={item.rate || 0} size={20} />
                                <span>({item.rate ? item.rate.toFixed(2) : '0.00'})</span>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                    <FieldList fields={fieldsEvaluation} showFieldsFeedback={configuration.microaspects} />
                  </div>
                  {configuration.background && <div style={{ borderBottom: '1px solid #bdbdbd' }} />}
                  {configuration.background && (
                    <div className={styles.generalDataEvaluation}>
                      <label className={styles.labelTitle}>{t('athleteDashboard.context')}</label>
                      <div className={styles.textWrapper}>
                        <CustomFields
                          fields={modifiedAthlete.background}
                          setFormFields={() => {}}
                          className={styles.customFields}
                          disableAll={true}
                        />
                      </div>
                    </div>
                  )}
                  {user.data?.organization?.teamId === 889 && (
                    <div className={styles.assignContainer}>
                      <div className={styles.formGroup}>
                        <label className={styles.formLabel}>{t('athleteDashboard.general_manager')}:</label>
                        <Input
                          className={styles.largeTextField}
                          type="text"
                          value={modifiedAthlete?.assigns?.gerente_geral ?? ''}
                          onChange={e => {
                            e.persist();
                            setModifiedAthlete({
                              ...modifiedAthlete,
                              assigns: { ...modifiedAthlete.assigns, gerente_geral: e.target.value },
                            });
                          }}
                        />
                      </div>
                      <div className={styles.formGroup}>
                        <label className={styles.formLabel}>{t('athleteDashboard.coord_technical')}:</label>
                        <Input
                          className={styles.largeTextField}
                          type="text"
                          value={modifiedAthlete?.assigns?.coord_tecnico1 ?? ''}
                          onChange={e => {
                            e.persist();
                            setModifiedAthlete({
                              ...modifiedAthlete,
                              assigns: { ...modifiedAthlete.assigns, coord_tecnico1: e.target.value },
                            });
                          }}
                        />
                      </div>
                      <div className={styles.formGroup}>
                        <label className={styles.formLabel}>{t('athleteDashboard.coord_technical')}:</label>
                        <Input
                          className={styles.largeTextField}
                          type="text"
                          value={modifiedAthlete?.assigns?.coord_tecnico2 ?? ''}
                          onChange={e => {
                            e.persist();
                            setModifiedAthlete({
                              ...modifiedAthlete,
                              assigns: { ...modifiedAthlete.assigns, coord_tecnico2: e.target.value },
                            });
                          }}
                        />
                      </div>
                      <div className={styles.formGroup}>
                        <label className={styles.formLabel}>{t('athleteDashboard.coord_capture')}:</label>
                        <Input
                          className={styles.largeTextField}
                          type="text"
                          value={modifiedAthlete?.assigns?.coord_captacao ?? ''}
                          onChange={e => {
                            e.persist();
                            setModifiedAthlete({
                              ...modifiedAthlete,
                              assigns: { ...modifiedAthlete.assigns, coord_captacao: e.target.value },
                            });
                          }}
                        />
                      </div>
                      <div className={styles.formGroup}>
                        <label className={styles.formLabel}>{t('athleteDashboard.coach')}:</label>
                        <Input
                          className={styles.largeTextField}
                          type="text"
                          value={modifiedAthlete?.assigns?.treinador ?? ''}
                          onChange={e => {
                            e.persist();
                            setModifiedAthlete({
                              ...modifiedAthlete,
                              assigns: { ...modifiedAthlete.assigns, treinador: e.target.value },
                            });
                          }}
                        />
                      </div>
                      <div className={styles.formGroup}>
                        <label className={styles.formLabel}>{t('athleteDashboard.prep_of_goalkeepers')}:</label>
                        <Input
                          className={styles.largeTextField}
                          type="text"
                          value={modifiedAthlete?.assigns?.prep_goleiros ?? ''}
                          onChange={e => {
                            e.persist();
                            setModifiedAthlete({
                              ...modifiedAthlete,
                              assigns: { ...modifiedAthlete.assigns, prep_goleiros: e.target.value },
                            });
                          }}
                        />
                      </div>
                    </div>
                  )}
                  {user.data?.organization?.teamId === 1286 && (
                    <div className={styles.assignContainerPalmeiras}>
                      <div className={styles.formGroup}>
                        <label className={styles.formLabel}>{t('athleteDashboard.assign_name')} 1:</label>
                        <Input
                          className={styles.largeTextField}
                          type="text"
                          value={modifiedAthlete?.assigns?.[0]?.name ?? ''}
                          onChange={e => {
                            e.persist();
                            const updatedAssigns = [...(modifiedAthlete?.assigns || [])];
                            updatedAssigns[0] = { ...updatedAssigns[0], name: e.target.value };
                            setModifiedAthlete({ ...modifiedAthlete, assigns: updatedAssigns });
                          }}
                        />
                      </div>
                      <div className={styles.formGroup}>
                        <label className={styles.formLabel}>{t('athleteDashboard.assign_cargo')} 1:</label>
                        <Menu
                          className={styles.largeSelect}
                          title={`${t('athleteDashboard.assign_cargo')} 1:`}
                          placeholder={t('organi.box20')}
                          allowEmpty
                          options={cargosSelect}
                          value={modifiedAthlete?.assigns?.[0]?.cargo ?? ''}
                          setValue={value => {
                            const updatedAssigns = [...(modifiedAthlete?.assigns || [])];
                            updatedAssigns[0] = { ...updatedAssigns[0], cargo: value };
                            setModifiedAthlete({ ...modifiedAthlete, assigns: updatedAssigns });
                          }}
                          onClear={() => {
                            const updatedAssigns = [...(modifiedAthlete?.assigns || [])];
                            if (updatedAssigns[0]) {
                              updatedAssigns[0] = { ...updatedAssigns[0], cargo: '' };
                            }
                            setModifiedAthlete({ ...modifiedAthlete, assigns: updatedAssigns });
                          }}
                        />
                      </div>
                      <div className={styles.formGroup}>
                        <label className={styles.formLabel}>{t('athleteDashboard.assign_name')} 2:</label>
                        <Input
                          className={styles.largeTextField}
                          type="text"
                          value={modifiedAthlete?.assigns?.[1]?.name ?? ''}
                          onChange={e => {
                            e.persist();
                            const updatedAssigns = [...(modifiedAthlete?.assigns || [])];
                            updatedAssigns[1] = { ...updatedAssigns[1], name: e.target.value };
                            setModifiedAthlete({ ...modifiedAthlete, assigns: updatedAssigns });
                          }}
                        />
                      </div>
                      <div className={styles.formGroup}>
                        <label className={styles.formLabel}>{t('athleteDashboard.assign_cargo')} 2:</label>
                        <Menu
                          className={styles.largeSelect}
                          title={`${t('athleteDashboard.assign_cargo')} 2:`}
                          placeholder={t('organi.box20')}
                          allowEmpty
                          options={cargosSelect}
                          value={modifiedAthlete?.assigns?.[1]?.cargo ?? ''}
                          setValue={value => {
                            const updatedAssigns = [...(modifiedAthlete?.assigns || [])];
                            updatedAssigns[1] = { ...updatedAssigns[1], cargo: value };
                            setModifiedAthlete({ ...modifiedAthlete, assigns: updatedAssigns });
                          }}
                          onClear={() => {
                            const updatedAssigns = [...(modifiedAthlete?.assigns || [])];
                            if (updatedAssigns[1]) {
                              updatedAssigns[1] = { ...updatedAssigns[1], cargo: '' };
                            }
                            setModifiedAthlete({ ...modifiedAthlete, assigns: updatedAssigns });
                          }}
                        />
                      </div>
                      <div className={styles.formGroup}>
                        <label className={styles.formLabel}>{t('athleteDashboard.assign_name')} 3:</label>
                        <Input
                          className={styles.largeTextField}
                          type="text"
                          value={modifiedAthlete?.assigns?.[2]?.name ?? ''}
                          onChange={e => {
                            e.persist();
                            const updatedAssigns = [...(modifiedAthlete?.assigns || [])];
                            updatedAssigns[2] = { ...updatedAssigns[2], name: e.target.value };
                            setModifiedAthlete({ ...modifiedAthlete, assigns: updatedAssigns });
                          }}
                        />
                      </div>
                      <div className={styles.formGroup}>
                        <label className={styles.formLabel}>{t('athleteDashboard.assign_cargo')} 3:</label>
                        <Menu
                          className={styles.largeSelect}
                          title={`${t('athleteDashboard.assign_cargo')} 3:`}
                          placeholder={t('organi.box20')}
                          allowEmpty
                          options={cargosSelect}
                          value={modifiedAthlete?.assigns?.[2]?.cargo ?? ''}
                          setValue={value => {
                            const updatedAssigns = [...(modifiedAthlete?.assigns || [])];
                            updatedAssigns[2] = { ...updatedAssigns[2], cargo: value };
                            setModifiedAthlete({ ...modifiedAthlete, assigns: updatedAssigns });
                          }}
                          onClear={() => {
                            const updatedAssigns = [...(modifiedAthlete?.assigns || [])];
                            if (updatedAssigns[2]) {
                              updatedAssigns[2] = { ...updatedAssigns[2], cargo: '' };
                            }
                            setModifiedAthlete({ ...modifiedAthlete, assigns: updatedAssigns });
                          }}
                        />
                      </div>
                      <div className={styles.formGroup}>
                        <label className={styles.formLabel}>{t('athleteDashboard.assign_name')} 4:</label>
                        <Input
                          className={styles.largeTextField}
                          type="text"
                          value={modifiedAthlete?.assigns?.[3]?.name ?? ''}
                          onChange={e => {
                            e.persist();
                            const updatedAssigns = [...(modifiedAthlete?.assigns || [])];
                            updatedAssigns[3] = { ...updatedAssigns[3], name: e.target.value };
                            setModifiedAthlete({ ...modifiedAthlete, assigns: updatedAssigns });
                          }}
                        />
                      </div>
                      <div className={styles.formGroup}>
                        <label className={styles.formLabel}>{t('athleteDashboard.assign_cargo')} 4:</label>
                        <Menu
                          className={styles.largeSelect}
                          title={`${t('athleteDashboard.assign_cargo')} 4:`}
                          placeholder={t('organi.box20')}
                          allowEmpty
                          options={cargosSelect}
                          value={modifiedAthlete?.assigns?.[3]?.cargo ?? ''}
                          setValue={value => {
                            const updatedAssigns = [...(modifiedAthlete?.assigns || [])];
                            updatedAssigns[3] = { ...updatedAssigns[3], cargo: value };
                            setModifiedAthlete({ ...modifiedAthlete, assigns: updatedAssigns });
                          }}
                          onClear={() => {
                            const updatedAssigns = [...(modifiedAthlete?.assigns || [])];
                            if (updatedAssigns[3]) {
                              updatedAssigns[3] = { ...updatedAssigns[3], cargo: '' };
                            }
                            setModifiedAthlete({ ...modifiedAthlete, assigns: updatedAssigns });
                          }}
                        />
                      </div>
                      <div className={styles.formGroup}>
                        <label className={styles.formLabel}>{t('athleteDashboard.assign_name')} 5:</label>
                        <Input
                          className={styles.largeTextField}
                          type="text"
                          value={modifiedAthlete?.assigns?.[4]?.name ?? ''}
                          onChange={e => {
                            e.persist();
                            const updatedAssigns = [...(modifiedAthlete?.assigns || [])];
                            updatedAssigns[4] = { ...updatedAssigns[4], name: e.target.value };
                            setModifiedAthlete({ ...modifiedAthlete, assigns: updatedAssigns });
                          }}
                        />
                      </div>
                      <div className={styles.formGroup}>
                        <label className={styles.formLabel}>{t('athleteDashboard.assign_cargo')} 5:</label>
                        <Menu
                          className={styles.largeSelect}
                          title={`${t('athleteDashboard.assign_cargo')} 5:`}
                          placeholder={t('organi.box20')}
                          allowEmpty
                          options={cargosSelect}
                          value={modifiedAthlete?.assigns?.[4]?.cargo ?? ''}
                          setValue={value => {
                            const updatedAssigns = [...(modifiedAthlete?.assigns || [])];
                            updatedAssigns[4] = { ...updatedAssigns[4], cargo: value };
                            setModifiedAthlete({ ...modifiedAthlete, assigns: updatedAssigns });
                          }}
                          onClear={() => {
                            const updatedAssigns = [...(modifiedAthlete?.assigns || [])];
                            if (updatedAssigns[4]) {
                              updatedAssigns[4] = { ...updatedAssigns[4], cargo: '' };
                            }
                            setModifiedAthlete({ ...modifiedAthlete, assigns: updatedAssigns });
                          }}
                        />
                      </div>
                      <div className={styles.formGroup}>
                        <label className={styles.formLabel}>{t('athleteDashboard.assign_name')} 6:</label>
                        <Input
                          className={styles.largeTextField}
                          type="text"
                          value={modifiedAthlete?.assigns?.[5]?.name ?? ''}
                          onChange={e => {
                            e.persist();
                            const updatedAssigns = [...(modifiedAthlete?.assigns || [])];
                            updatedAssigns[5] = { ...updatedAssigns[5], name: e.target.value };
                            setModifiedAthlete({ ...modifiedAthlete, assigns: updatedAssigns });
                          }}
                        />
                      </div>
                      <div className={styles.formGroup}>
                        <label className={styles.formLabel}>{t('athleteDashboard.assign_cargo')} 6:</label>
                        <Menu
                          className={styles.largeSelect}
                          title={`${t('athleteDashboard.assign_cargo')} 6:`}
                          placeholder={t('organi.box20')}
                          allowEmpty
                          options={cargosSelect}
                          value={modifiedAthlete?.assigns?.[5]?.cargo ?? ''}
                          setValue={value => {
                            const updatedAssigns = [...(modifiedAthlete?.assigns || [])];
                            updatedAssigns[5] = { ...updatedAssigns[5], cargo: value };
                            setModifiedAthlete({ ...modifiedAthlete, assigns: updatedAssigns });
                          }}
                          onClear={() => {
                            const updatedAssigns = [...(modifiedAthlete?.assigns || [])];
                            if (updatedAssigns[5]) {
                              updatedAssigns[5] = { ...updatedAssigns[5], cargo: '' };
                            }
                            setModifiedAthlete({ ...modifiedAthlete, assigns: updatedAssigns });
                          }}
                        />
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          ) : (
            <div className={styles.evaluation}>{t('club.box17')}</div>
          ))}
        {configuration.historic && (
          <Card softShadow className={[styles.performanceByTeam, styles.tableCard].join(' ')}>
            {athleteHistoricalReduce.length > 0 ? (
              <Table
                flat
                fullhead={<label className={styles.labelTitle}>{t('club.box19')}</label>}
                theads={[
                  t('slider_menu.seas'),
                  t('athlete_list.box2'),
                  t('match.comp'),
                  <img src={IcoTempo} alt="tempo" />,
                  <img src={IcoJogos} alt="jogos" />,
                  <img src={IcoTitular} alt="titular" />,
                  <img src={IcoEntrou} alt="entrou" />,
                  <img src={IcoSaiu} alt="saiu" />,
                  <img src={IcoGols} alt="gols" />,
                  <img src={IcoAssistencias} alt="assistências" />,
                  <img src={IcoAmarelos} alt="amarelos" />,
                  <img src={IcoAmareloVermelho} alt="amarelo vermelho" />,
                  <img src={IcoVermelhos} alt="vermelho" />,
                ]}
                data={athleteHistoricalReduce}
              />
            ) : (
              t('rest.box13')
            )}
          </Card>
        )}
        {configuration.career && (
          <Card softShadow className={[styles.transfers, styles.tableCard].join(' ')}>
            {athleteTransfers.length > 0 ? (
              <div className={styles.athleteTransfers}>
                <Table
                  fullhead={<label className={styles.labelTitle}>{t('club.box20')}</label>}
                  flat
                  theads={[
                    t('slider_menu.seas'),
                    t('table.data'),
                    t('table.origin'),
                    t('table.destiny'),
                    t('table.age_moment'),
                    t('table.price'),
                  ]}
                  data={athleteTransfers}
                  footer={`${t('athlete_card.text7')} ${athleteTransfersTotal} M €`}
                />
              </div>
            ) : (
              t('rest.box14')
            )}
          </Card>
        )}
        {configuration.career && (
          <Card softShadow className={[styles.contracts, styles.tableCard].join(' ')}>
            <div className={styles.athleteContracts}>
              {athleteContracts.length > 0 ? (
                <Table
                  fullhead={<label className={styles.labelTitle}>{t('athlete_card.text1')}</label>}
                  flat
                  theads={[
                    t('athlete_card.text2'),
                    t('athlete_list.box2'),
                    t('athlete_card.text5'),
                    t('contact_card.type'),
                    t('filters.contract'),
                    t('athlete_card.text3'),
                    t('athlete_card.text4'),
                  ]}
                  data={athleteContracts}
                />
              ) : (
                t('rest.box15')
              )}
            </div>
          </Card>
        )}
      </div>
    </>
  );
}

ReportEdition.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
  configuration: PropTypes.instanceOf(Object).isRequired,
  setAthletesInfoData: PropTypes.func.isRequired,
  athletesInfoData: PropTypes.array.isRequired,
  athleteInfoData: PropTypes.instanceOf(Object).isRequired,
};
