import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { RadarChart, TextArea } from 'components/template';
import styles from './SideSection.module.scss';
import FieldList from '../FieldList/FieldList';

export default function SideSection({ fields, setFields }) {
  const radarChartLabels = fields ? fields.map(item => String(item.title)) : [];
  const radarChartSeries = fields ? fields.map(item => item.rate || 0) : [];

  const plotOptions = {
    chart: {
      background: 'white',
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      radar: {
        size: 120,
        polygons: {
          strokeColors: '#ccc',
          connectorColors: '#ccc',
          fill: {
            colors: ['#fff', '#e0e0e0'],
          },
        },
      },
    },
    xaxis: {
      labels: {
        style: { colors: Array(fields.length).fill('#000'), fontSize: '12px' },
      },
    },
  };

  const radarChartData = {
    labels: radarChartLabels,
    series: [
      {
        data: radarChartSeries,
      },
    ],
  };

  return (
    <div className={styles.sideSection}>
      {fields[0].hasChartForEach ? <></> : <RadarChart height={290} data={radarChartData} options={plotOptions} />}
      <FieldList fields={fields} setFields={setFields} />
    </div>
  );
}

SideSection.propTypes = {
  fields: PropTypes.instanceOf(Array).isRequired,
  feedback: PropTypes.string.isRequired,
  setFeedback: PropTypes.func.isRequired,
  teamContext: PropTypes.string.isRequired,
  setTeamContext: PropTypes.func.isRequired,
};
