import React, { useState, useEffect } from 'react';
import { FaTrashAlt, FaSearch, FaClipboardList } from 'react-icons/fa';
import { IconButton, Input, Menu, Button, SelectionButton } from 'components/template';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import { positionOptions } from 'shared/mocks';
import { getAnalysts } from 'store/ducks/user';
import AdvancedSearchIcon from 'assets/ico/appbar/advanced_search_purple.svg';
import MoreFilters from './MoreFilters/MoreFilters';
import { useSelector } from 'react-redux';

export default function ActionBar({ evaluations, filters, setFilters, setIsOpenDialog, checkedEvaluations }) {
  const { t } = useTranslation();
  const [analysts, setAnalysts] = useState(null);
  const [timeoutId, setTimeoutId] = useState(null);
  const [isModalVisible, setModalVisible] = useState(false);
  const [moreFiltersCount, setMoreFiltersCount] = useState(0);
  const user = useSelector(state => state.user);
  const teamId = user.data.organization.teamId;

  const closeModal = () => {
    setModalVisible(false);
  };

  //Bloqueia filtro analistas para o bragantino e botafogo
  const blockAnalystFilter = [927, 922].includes(user?.data?.organization?.id?.team?.id);

  useEffect(() => {
    // eslint-disable-line react-hooks/exhaustive-deps

    if ((analysts == null && user?.data?.plan?.mensageria) || (analysts == null && !blockAnalystFilter)) {
      getAnalysts().then(usersApi => {
        setAnalysts(usersApi?.data?.analysts);
      });
    } else if (analysts == null && user?.data?.plan?.mensageria === false && blockAnalystFilter) {
      setAnalysts([{ first_name: user.data?.name, last_name: '', id: user.data?.id }]);
      setFilters({ ...filters, analyst: user.data?.id ? user.data?.id : null });
    }
  });

  const handleInputChange = value => {
    clearTimeout(timeoutId);
    setTimeoutId(
      setTimeout(() => {
        setFilters({ ...filters, name: value });
      }, 800)
    );
  };

  //Bloqueia filtro analistas para o bragantino
  const onClearHandler =
    user?.data?.plan?.mensageria === false && blockAnalystFilter
      ? undefined
      : () => {
          setFilters({ ...filters, analyst: null });
        };

  const onSetHandler = val =>
    user?.data?.plan?.mensageria === false && blockAnalystFilter
      ? undefined
      : setFilters({ ...filters, analyst: val ? val : null });

  return (
    <div className={styles.container}>
      <div className={styles.actionBar}>
        <div className={styles.actionButtons}>
          <Button
            className={styles.reportButton}
            onClick={() =>
              window.open(`/relatorios/criar?ids=${checkedEvaluations.map(evaluation => evaluation.athlete).join(',')}`)
            }
            color="vividGreen"
            disabled={checkedEvaluations.length === 0}
          >
            <div className={styles.text}>
              {t('athlete_list.tip')}
              <FaClipboardList className={styles.icon} />
            </div>
          </Button>
          <IconButton
            className={styles.deleteButton}
            onClick={() => {
              setIsOpenDialog(true);
            }}
            icon={<FaTrashAlt />}
            text={t('buttons.excluir')}
            disabled={checkedEvaluations.length === 0}
          />
        </div>
        <Input
          rounded
          placeholder={t('agencies.athlete_name')}
          containerClassName={styles.inputContainer}
          onChange={e => handleInputChange(e.target.value)}
          icon={<FaSearch className={styles.icon} />}
          iconPosition="right"
        />
        <Menu
          title={t('table.analist')}
          rounded
          allowEmpty
          placeholder={t('charts.byAnalist')}
          className={styles.menu}
          options={
            analysts
              ? analysts.map(item => ({
                  label: item?.first_name || item?.last_name ? item?.first_name + ' ' + item?.last_name : item?.email,
                  value: item.id,
                }))
              : [
                  {
                    label: t('proj.text20'),
                    value: 0,
                  },
                ]
          }
          value={filters['analyst']}
          onClear={onClearHandler}
          setValue={val => onSetHandler(val)}
        />
        <Menu
          title={t('filters.pos')}
          rounded
          allowEmpty
          placeholder={t('charts.filterByPosition')}
          className={styles.menu}
          options={positionOptions.map(item => ({ label: t(`positionOptions.${item.value}`), value: item.label }))}
          value={filters['position']}
          onClear={() => {
            setFilters({ ...filters, position: '' });
          }}
          setValue={val => {
            setFilters({ ...filters, position: val });
          }}
        />
        <Menu
          title={t('config.dire')}
          rounded
          allowEmpty
          multiple
          className={styles.menu}
          placeholder={t('charts.filterByTargeting')}
          options={[
            { label: t('config.toApprove'), value: 'aprovar' },
            { label: t('config.cont'), value: t('config.contr') },
            { label: t('config.DESCARTAR'), value: t('config.discard') },
            { label: t('config.mon'), value: t('config.moni') },
            { label: t('config.toReprove'), value: 'reprovar' },
            { label: t('config.toEvaluate'), value: t('config.evaluate') },
          ]}
          value={filters['recommendation']}
          setValue={val => setFilters({ ...filters, recommendation: val })}
          onClear={() => setFilters({ ...filters, recommendation: [] })}
        />

        <Button className={styles.moreFilters} color="white" onClick={() => setModalVisible(true)}>
          <div className={styles.textMoreFilters}>
            <div className={styles.moreFiltersContainer}>
              <img src={AdvancedSearchIcon} className={styles.iconRight} alt="advanced search" />
              {moreFiltersCount > 0 && <div className={styles.counter}>{moreFiltersCount}</div>}
            </div>
            {t('charts.moreFilters')}
          </div>
        </Button>
        <MoreFilters
          isVisible={isModalVisible}
          closeModal={closeModal}
          applyFilter={moreFilters => {
            setFilters({ ...filters, ...moreFilters });
            setModalVisible(false);
          }}
          onFilterCountChange={filterCount => setMoreFiltersCount(filterCount)}
        />
      </div>
      <div className={styles.viewType}>
        <SelectionButton
          items={[
            { label: t('myClub.negotiations.list'), value: true },
            { label: t('myClub.negotiations.report'), value: false },
          ]}
          selected={filters['viewList']}
          onClick={val => setFilters({ ...filters, viewList: val })}
        />
      </div>
    </div>
  );
}
