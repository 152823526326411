import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Creators as athleteActions } from 'store/ducks/athlete';
import { Creators as clubActions } from 'store/ducks/club';
import { Creators as layoutCreators } from 'store/ducks/layout';
import { useTranslation } from 'react-i18next';
import isEmpty from 'lodash/isEmpty';

import { FaEye, FaRegCalendarAlt, FaFileAlt } from 'react-icons/fa';
import { IoIosFootball } from 'react-icons/io';
import { Card, Spinner } from 'components/template';
import styles from './Evaluation.module.scss';
import TopBar from '../TopBar/TopBar';
import ConfigBar from './ConfigBar/ConfigBar';
import { useMount } from 'shared/hooks';
import moment from 'moment';
import { defaultClubEvaluationFields } from 'shared/mocks';
import ModelEvaluation from './ModelEvaluation/ModelEvaluation';
import * as yup from 'yup';

const Evaluation = ({ match, isNew, history }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector(state => state.user.data);
  const athleteData = useSelector(state => state.athlete.athleteData);
  const evaluationData = useSelector(state => state.athlete.evaluationData);
  const evaluationFields = useSelector(state => state.club.evaluationFields);

  const teamId = user.organization.teamId;

  //bar de cima
  const [generalRate, setGeneralRate] = useState(0);
  const [targeting, setTargeting] = useState('');
  const [priority, setPriority] = useState('');
  const [mode, setMode] = useState('');

  //usado na avaliacao
  const [evaluationsFields, setEvaluationsFields] = useState([]);
  const [opinionFields, setOpinionFields] = useState([]);
  const [contextFields, setContextFields] = useState([]);
  const [dataFields, setDataFields] = useState([]);
  const [dataEvaluation, setDataEvaluation] = useState({});
  const [hasRun, setHasRun] = useState(false);

  const [evaluationSchema, setEvaluationSchema] = useState({});
  const [errors, setErrors] = useState({});

  const cleanEvaluation = useCallback(() => {
    dispatch(athleteActions.resetEvaluation());
    dispatch(clubActions.resetEvaluationFields());
  }, [dispatch]);

  useMount(() => {
    dispatch(clubActions.getEvaluationFields({ is_new_evaluation: true }));
    dispatch(athleteActions.getAthleteData(match.params.athleteId));

    if (!isNew) {
      dispatch(
        athleteActions.getEvaluation({
          athleteId: match.params.athleteId,
          id: match.params.evaluationId,
        })
      );
    }

    return () => cleanEvaluation();
  });

  const updateFields = useCallback(
    data => {
      if (data?.mode) setMode(data.mode);
      if (data?.recommendation) setTargeting(data.recommendation.toLowerCase());
      if (data?.priority) setPriority(data.priority.toLowerCase());

      setOpinionFields(data?.data?.values?.opinion_fields ?? data?.data?.opinion_fields ?? []);
      setEvaluationsFields(data?.data?.values?.fields ?? data?.data?.evaluation_fields ?? []);
      setContextFields(data?.data?.values?.context_fields ?? data?.data?.context_fields ?? []);

      if (data?.data || data?.data?.values) {
        const requiredFieldNamesOpinionFields = getRequiredFieldNames(
          data?.values?.opinion_fields ?? data?.data?.opinion_fields ?? []
        );
        const requiredFieldNamesContextFields = getRequiredFieldNames(
          data?.values?.context_fields ?? data?.data?.context_fields ?? []
        );
        setEvaluationSchema({
          opinionsRequired: requiredFieldNamesOpinionFields,
          contextRequired: requiredFieldNamesContextFields,
        });
      }
    },
    [setMode]
  );

  useEffect(() => {
    if (!hasRun) {
      if (evaluationData.data.id) {
        if (evaluationData.data.isNewEvaluation) {
          setDataEvaluation(evaluationData.data);
        } else {
          const evaFields = evaluationFields.data.find(s => s.mode === evaluationData.data.mode);
          const newDataEvalu = {
            ...evaluationData.data,
            data: {
              teamId: 456,
              values: {
                fields:
                  evaluationData?.data?.values?.fields.length > 0
                    ? evaluationData?.data?.values?.fields
                    : evaFields?.data?.evaluation_fields,
                opinion_fields: evaFields?.data?.opinion_fields.map(field => {
                  if (field.name === 'parecer') {
                    return { ...field, value: evaluationData.data?.data?.feedback };
                  } else if (field.name === 'context_parecer') {
                    return { ...field, value: evaluationData.data?.data?.teamContext };
                  }
                  return field;
                }),
                context_fields: evaFields?.data?.context_fields.map(field => {
                  if (field.name === 'context_parecer') {
                    return { ...field, value: evaluationData.data?.data?.teamContext };
                  }
                  return field;
                }),
              },
            },
          };
          setDataEvaluation(newDataEvalu);
        }
        setHasRun(true);
      }
    }
  }, [evaluationData.data, evaluationFields.data, setHasRun]);

  function getRequiredFieldNames(data) {
    const requiredFields = [];
    data.forEach(field => {
      if (field.required) {
        requiredFields.push({ name: field.name, type: field?.type ?? 'stars' });
      }
    });
    return requiredFields;
  }

  // redirect to the created evaluation
  useEffect(() => {
    if (evaluationData.saved) {
      dispatch(athleteActions.resetEvaluation());
      dispatch(
        layoutCreators.showToast({
          onCloseHandler: () => dispatch(athleteActions.saveEvaluationErrorHandled()),
          content: t('organi.evaluationSaved'),
          duration: 1500,
          type: 'success',
        })
      );
      if (isNew) {
        const id = evaluationData.data.id;
        const athleteId = match.params.athleteId;
        const url = `/atletas/${athleteId}/avaliacoes/${id}`;
        history.push(url);
      }
    }
  }, [evaluationData.saved, evaluationData.data, history, match.url, dispatch, isNew, match.params.athleteId]); // eslint-disable-line react-hooks/exhaustive-deps

  // effect used to calculate the general evaluation
  useEffect(() => {
    if (evaluationsFields?.length) {
      const sumRates = evaluationsFields.map(f => f?.rate ?? 0).reduce((acc, rate) => acc + rate);
      setGeneralRate(sumRates / evaluationsFields.length);
    }
  }, [evaluationsFields]);

  // error handler
  useEffect(() => {
    if (evaluationData.error) {
      dispatch(
        layoutCreators.showToast({
          onCloseHandler: () => dispatch(athleteActions.saveEvaluationErrorHandled()),
          content: evaluationData.error,
          duration: 1500,
        })
      );
    }
  }, [evaluationData.error, dispatch]);

  // empty evaluation data
  useEffect(() => {
    if (isNew && evaluationFields.data) {
      updateFields(evaluationFields.data.find(s => s.mode === mode));
    }
  }, [dataEvaluation, evaluationFields.data, updateFields, mode, isNew]);

  // existing evaluation data fillup
  useEffect(() => {
    if (!isNew && dataEvaluation.id && evaluationFields.data) {
      updateFields(dataEvaluation);
    }
  }, [dataEvaluation, updateFields, isNew, evaluationFields.data]);

  function saveEvaluationHandler() {
    const requestData = {
      org: user.organization?.id,
      target: match.params.athleteId,
      method: 'GERAL',
      mode: mode.toUpperCase(),
      recommendation: targeting.toUpperCase(),
      priority: priority.toUpperCase(),
      author_id: user.id,
      evaluation_date: moment().format('YYYY-MM-DD'),
      data: {
        teamId: user.organization?.teamId,
        isNewEvaluation: true,
        values: {
          fields: [...evaluationsFields],
          opinion_fields: [...dataFields.opinion_fields],
          context_fields: [...dataFields.context_fields],
        },
      },
      // match_position: dataFields?.position || null,
    };
    try {
      const errorsOpinion = dataFields.opinion_fields
        .filter(field =>
          evaluationSchema.opinionsRequired.some(
            criterion => criterion.name === field.name && field.value === '' && criterion.type === field.type
          )
        )
        .map(field => ({
          field: field.name,
          message: `O campo "${field.name}" é obrigatório.`,
        }));

      const errorsContext = dataFields.context_fields
        .filter(field =>
          evaluationSchema.contextRequired.some(
            criterion => criterion.name === field.name && field.value === '' && criterion.type === field.type
          )
        )
        .map(field => ({
          field: field.name,
          message: `O campo "${field.label}" é obrigatório.`,
        }));

      if (errorsOpinion.length > 0 || errorsContext.length > 0) {
        setErrors({ opinion: errorsOpinion, context: errorsContext });
        return;
      }
      dispatch(
        athleteActions.saveEvaluation({
          athleteId: match.params.athleteId,
          evaluationData: requestData,
          id: isNew ? null : match.params.evaluationId,
          filters: { is_new_evaluation: true },
        })
      );
      setHasRun(false);
      setErrors({ opinion: [], context: [] });
    } catch (error) {}
  }

  const setEvaluationsFieldsCb = useCallback(setEvaluationsFields, []);

  let evaluationHeader = null;

  if (!isEmpty(dataEvaluation) && !isNew) {
    evaluationHeader = (
      <div className={styles.evaluationHeaderInfo}>
        <h1>{t('evaluationData')}</h1>
        <div>
          <FaEye /> <span>{dataEvaluation?.author?.name ?? dataEvaluation?.author?.email}</span>
        </div>
        <div>
          <FaRegCalendarAlt /> <span>{new Date(dataEvaluation.created_at).toLocaleDateString('pt-BR') || ' '}</span>
        </div>
        <div>
          <IoIosFootball /> <span>{dataEvaluation.method}</span>
        </div>
        {dataEvaluation.mode_name && (
          <div>
            <FaFileAlt /> <span>{dataEvaluation.mode_name}</span>
          </div>
        )}
      </div>
    );
  }

  let content = null;

  if (athleteData.loading || evaluationFields.loading || evaluationData.loading) {
    content = (
      <div className={styles.loaderContainer}>
        <Spinner />
      </div>
    );
  } else if (athleteData.error) {
    content = (
      <Card softShadow className={styles.errorContainer}>
        <h2>{t(athleteData.error)}</h2>
      </Card>
    );
  } else {
    content = (
      <>
        <TopBar athlete={athleteData.data} />
        <div className={styles.innerContainer}>
          <ConfigBar
            configBar={{
              generalRate,
              targeting,
              priority,
              mode,
              evaluationFields,
              setTargeting,
              setPriority,
              setMode,
              teamId,
            }}
            updateFields={updateFields}
            evaluationData={dataEvaluation}
            saveEvaluation={saveEvaluationHandler}
          />
          <div className={styles.evaluationContainer}>
            <Card softShadow className={styles.contentCard}>
              <div className={styles.body}>
                {!mode ? (
                  <p className={styles.noModeSelected}>{t('field.selec')}</p>
                ) : (
                  <>
                    <div>
                      <ModelEvaluation
                        opinionFields={opinionFields}
                        contextFields={contextFields}
                        evaluationsFields={evaluationsFields}
                        dataFields={dataFields}
                        setDataFields={setDataFields}
                        setEvaluationsFields={setEvaluationsFieldsCb}
                        errors={errors}
                      />
                    </div>
                    <div className={styles.header}>{evaluationHeader}</div>
                  </>
                )}
              </div>
            </Card>
          </div>
        </div>
      </>
    );
  }

  return <div className={styles.container}>{content}</div>;
};

Evaluation.defaultProps = {
  isNew: false,
};

Evaluation.propTypes = {
  match: PropTypes.instanceOf(Object).isRequired,
  isNew: PropTypes.bool,
};

export default withRouter(Evaluation);
