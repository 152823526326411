import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import styles from './FieldList.module.scss';
import FieldItem from './FieldItem/FieldItem';
import { useTranslation } from 'react-i18next';
import { RadarChart } from 'components/template';

const FieldList = ({ fields, setFields, showFieldsFeedback, onEditFinish }) => {
  const { t } = useTranslation();
  const handleSetField = useCallback(
    data => {
      setFields(f => {
        const newData = [...f];

        newData.forEach((item, j) => {
          if (item.title === data.title) {
            newData[j] = data;
          }
        });

        return newData;
      });
    },
    [setFields]
  );

  const hasSubFields = data => {
    return data.some(item => item.subfields);
  };

  const plotOptions = {
    chart: {
      background: 'white',
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      radar: {
        size: 70,
        polygons: {
          strokeColors: '#ccc',
          connectorColors: '#ccc',
          fill: {
            colors: ['#fff', '#e0e0e0'],
          },
        },
      },
    },
    xaxis: {
      labels: {
        style: { colors: Array(fields.length).fill('#000'), fontSize: '12px' },
      },
    },
  };

  const content = useMemo(() => {
    return fields
      ? fields.map((field, i) => {
          return (
            <div className={field.hasChartForEach && styles.hasChartForEach}>
              <FieldItem
                key={field.title}
                setField={setFields !== undefined ? handleSetField : () => {}}
                showFieldsFeedback={showFieldsFeedback ?? true}
                enableFieldsFeedbackUpdate={setFields !== undefined}
                onEditFinish={onEditFinish}
                field={field}
                index={i + 1}
              />
              {field.hasChartForEach && (
                <div className={styles.radarChart}>
                  <RadarChart
                    height={290}
                    data={{
                      labels: field.subfields.map(item => String(item.title)),
                      series: [
                        {
                          data: field.subfields.map(item => item.rate || 0),
                        },
                      ],
                    }}
                    options={plotOptions}
                  />
                </div>
              )}
            </div>
          );
        })
      : '';
  }, [fields, handleSetField, showFieldsFeedback, onEditFinish, setFields]);

  return (
    <div className={hasSubFields(fields) ? styles.containerWithSubfields : styles.containerWithoutSubfields}>
      {content}
    </div>
  );
};

FieldList.propTypes = {
  fields: PropTypes.instanceOf(Object).isRequired,
};

export default FieldList;
