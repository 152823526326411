import React from 'react';
import PropTypes from 'prop-types';
import { Link, useHistory } from 'react-router-dom';
import { Avatar, IconButton, ToolTip } from 'components/template';
import styles from './TopBar.module.scss';
import moment from 'moment';
import { BiArrowBack } from 'react-icons/bi';
import { MdAssignment } from 'react-icons/md';
import MonitoringButton from 'components/MonitoringButton/MonitoringButton';
import { useTranslation } from 'react-i18next';

export default function TopBar({ athlete, setReportAthletes, border }) {
  const { t } = useTranslation();
  const history = useHistory();
  const team = athlete?.current_team;
  const loanTeam = athlete?.src_team;

  const goBackToPreviousPage = () => {
    const currentPath = history.location.pathname;

    // Verifica se a URL atual corresponde ao padrão /atletas/{id}/avaliacoes/{id}
    const regex = /^\/atletas\/(\d+)\/avaliacoes\/(\d+)$/;
    const newRegex = /^\/atletas\/(\d+)\/avaliacoes\/nova$/;
    const match = currentPath.match(regex);
    const newMatch = currentPath.match(newRegex);

    if (match) {
      // Se corresponder, redireciona para /atletas/{id}/avaliacoes/
      const atletaId = match[1];
      history.push(`/atletas/${atletaId}/avaliacoes/`);
    } else if (newMatch) {
      // Se corresponder, redireciona para /atletas/{id}/avaliacoes/
      const atletaId = newMatch[1];
      history.push(`/atletas/${atletaId}/avaliacoes/`);
    } else {
      // Lógica original para retornar à página anterior
      const pathParts = currentPath.split('/');
      const currentPartsCount = pathParts.length;

      let previousPath = '';
      for (let i = currentPartsCount - 1; i > 0; i--) {
        const previousParts = pathParts.slice(0, i).join('/');
        if (!history.location.pathname.includes(previousParts)) {
          previousPath = previousParts;
          break;
        }
      }

      if (previousPath === '') {
        const previews = history.location.state?.previews;
        if (previews) {
          previousPath = previews;
        }
      }

      history.push(previousPath);
    }
  };

  return (
    <div className={styles.topbar} style={{ borderBottom: border && '1px solid #BDBDBD' }}>
      {/* left container */}
      <div className={styles.left}>
        <div className={styles.arrow_container}>
          <IconButton
            className={styles.arrow}
            icon={<BiArrowBack />}
            onClick={() => {
              goBackToPreviousPage();
            }}
          />
        </div>
        <div className={styles.athlete_container}>
          <div className={styles.avatar_container}>
            <Avatar className={styles.avatar} img={athlete?.photo ?? ''} name={athlete?.fullname} />
          </div>
          <div className={styles.general_info_container}>
            <div className={styles.name_container}>
              <span className={styles.nickname}>{athlete?.nickname ?? ''}</span>
              <span className={styles.fullname}>{athlete?.fullname}</span>
            </div>
            <div className={styles.info_container}>
              <div className={styles.nationality_container}>
                {!!athlete?.nationalities?.length &&
                  athlete.nationalities.map((item, i) => (
                    <img
                      className={styles.flag}
                      src={item.flag}
                      alt={item.name}
                      draggable="false"
                      key={`${item.name}${i}`}
                    />
                  ))}
                <span className={styles.country}>{athlete?.country?.name}</span>
              </div>
              {!!athlete?.nationalities?.length && athlete?.age && '|'}
              <span className={styles.age}>
                {athlete?.age?.slice(5, 7)} {t('athlete_card.idade')}
              </span>
              {athlete?.age && athlete?.position && '|'}
              <span className={styles.position}>{t(`positionOptionsRaw.${athlete?.position}`)}</span>
            </div>
          </div>
        </div>
      </div>

      {/* middle container */}
      <div className={styles.center}>
        {!!team?.id && (
          <div className={styles.team_container}>
            <span className={styles.team_header}>{t('club.box21')}</span>
            <Link to={`/clube/${team?.id}/elenco`} className={styles.team_link}>
              <Avatar
                noBorder
                img={team?.emblem ?? ''}
                className={styles.shield}
                classNameOnError={styles.withoutEmblem}
                name={team?.longname}
                alt=""
                draggable="false"
              />
              <div className={styles.team_info_container}>
                <span className={styles.team_nickname}>{team?.nickname}</span>
                {!!athlete?.contract_info?.current_contract_end && (
                  <div className={styles.team_contract_date}>
                    {t('club.box22') + moment(athlete?.contract_info?.current_contract_end).format('DD/MM/YYYY')}
                  </div>
                )}
              </div>
            </Link>
          </div>
        )}
        {!!loanTeam?.id && (
          <div className={styles.team_container}>
            <span className={styles.team_header}>{t('club.box23')}</span>
            <Link to={`/clube/${loanTeam?.id}/elenco`} className={styles.team_link}>
              {loanTeam?.emblem && (
                <Avatar
                  noBorder
                  img={loanTeam?.emblem ?? ''}
                  className={styles.shield}
                  name={loanTeam?.longname}
                  alt=""
                  draggable="false"
                />
              )}
              <div className={styles.team_info_container}>
                <span className={styles.team_nickname}>{loanTeam?.nickname}</span>
                {!!athlete?.contract_info?.src_contract_end && (
                  <div className={styles.team_contract_date}>
                    {t('club.box22') + moment(athlete?.contract_info?.src_contract_end).format('DD/MM/YYYY')}
                  </div>
                )}
              </div>
            </Link>
          </div>
        )}
        {/* <div className={styles.market_value_container}>
          <span className={styles.market_value_header}>VALUATION FOOTURE</span>
          <a
            draggable="false"
            href={`https://api.whatsapp.com/send?phone=${t('whatsapp.phone')}&text=${t(
              'whatsapp.valuation1'
            )}${athlete?.fullname ?? ''}${t('whatsapp.valuation2')}${team?.nickname ?? team?.longname ?? ''}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className={styles.market_value_span}>{t('athlete_card.buyValuation')}</span>
          </a>
        </div> */}
      </div>

      {/* right container */}
      <div className={styles.right}>
        <div className={styles.buttons_container}>
          {/* <IconButton className={styles.notification_button} icon={<FaBell />} /> */}
          {setReportAthletes && (
            <>
              <ToolTip message="Gerar relatório">
                <IconButton
                  className={styles.report_button}
                  icon={<MdAssignment />}
                  onClick={() => window.open(`/relatorios/criar?ids=${athlete.id}`)}
                />
              </ToolTip>
            </>
          )}
          <MonitoringButton className={styles.monitoring_button} filled={true} athlete={athlete} />
        </div>
      </div>
    </div>
  );
}

TopBar.propTypes = {
  athlete: PropTypes.instanceOf(Object).isRequired,
};
